import * as React from 'react';
import Mail from './Icons/Mail';
import Phone from './Icons/Phone';
import Location from './Icons/Location';
import * as styles from './ContactInfo.module.scss';
import {getLabel} from "../contentful/data-provider";

const ContactInfo = ({subtitle, labels, images}) => {
    return (
        <div className={styles.container}>
            <div className={styles.companyName}>
                {subtitle}
            </div>
            <div className={styles.contactList}>
                <address className={styles.contactItem}>
                    <Location/>
                    {getLabel('address-label', labels)} <br/>
                    {getLabel('address-line-2-label', labels)}
                </address>
                <div className={styles.contactItem}>
                    <Phone/>
                    {getLabel('phone-number-label', labels)} <br/>
                    {getLabel('fax-number-label', labels)}
                </div>
                <div className={styles.contactItem}>
                    <Mail/>
                    <a href={'mailto:' + getLabel('email-label', labels)}>{getLabel('email-label', labels)}</a>
                </div>
            </div>
            {images.length >= 2 &&
                <div className={styles.mapContainer}>
                    <a href="https://goo.gl/maps/6TeQKbr9P4yLiNZu6" target="_blank" rel="noreferrer">
                        <img src={images[1].url} alt={images[1].title}/>
                    </a>
                </div>
            }
        </div>
    )
};

export default ContactInfo;
