import * as React from 'react';
import {graphql} from 'gatsby';
import Layout from '../components/Layout';
import Columns from '../components/Columns';
import Section from '../components/Section';
import PageTitle from '../components/PageTitle';
import PageHeader from '../components/PageHeader';
import ContactInfo from '../components/ContactInfo';
import ContactForm from '../components/ContactForm';
import {getImage} from "../contentful/data-provider";
import FullWidthImage from '../components/FullWidthImage';

const ContactPage = ({data}) => {
    const {contentfulBasicPageSection, allContentfulLabel} = data;
    return (
        <Layout>
            <main>
                <PageHeader>
                    <PageTitle>
                        <h1>{contentfulBasicPageSection.title}</h1>
                    </PageTitle>
                </PageHeader>
                <Section hasMaxWidth isContactSection>
                    <Columns>
                        <ContactInfo subtitle={contentfulBasicPageSection.subtitle} labels={allContentfulLabel.nodes} images={contentfulBasicPageSection.images}/>
                        <ContactForm labels={allContentfulLabel.nodes}/>
                    </Columns>
                </Section>
                <Section isMobileHidden>
                    {contentfulBasicPageSection.images.length >= 1 &&
                        <FullWidthImage src={getImage(contentfulBasicPageSection.images[0]).url}/>
                    }
                </Section>
            </main>
        </Layout>
    )
}

export default ContactPage;

export function Head({data}) {
    const {contentfulBasicPageSection} = data;
    return (
        <title>{contentfulBasicPageSection.title} | Derschlag</title>
    )
}

export const query = graphql`
    query ($locale: String!) {
        contentfulBasicPageSection(
            contentful_id: {eq: "7BnUrzcuhZXmH3Lm6dCfgg"}
            node_locale: {eq: $locale}
        ) {
            ... BasicPageFields
        }
        allContentfulLabel (filter: {node_locale: {eq: $locale}}) {
            nodes {
                ... LabelFields
            }
        }
    }
`;
